var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-xl-8 order-lg-3 order-xl-1"},[_c('KTPortlet',_vm._b({scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-clean btn-sm btn-icon btn-icon-md",attrs:{"title":"يېڭىلاش"},on:{"click":_vm.onClickRefreshUserStatics}},[_c('i',{staticClass:"flaticon2-refresh-arrow"})])]},proxy:true},{key:"body",fn:function(){return [_c('Widget20_1',{attrs:{"title":_vm.widgetTitle,"update":_vm.userStaticUpdate,"desc":"نەپەر ئەزا يېڭىدىن قوشۇلدى"},on:{"count":_vm.widgetCount}})]},proxy:true}])},'KTPortlet',{
        title: 'ھەپتە ئىچىدىكى ئەزا سىتاستىكىسى',
        class: 'kt-portlet--fit kt-portlet--head-noborder',
        headClass: 'kt-portlet__space-x',
        headNoBorder: true,
        bodyFluid: true
        },false)),_c('div',{staticClass:"kt-space-20"}),_c('KTPortlet',_vm._b({scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"kt-portlet__space-x"},[_c('protocol-content')],1)]},proxy:true}])},'KTPortlet',{
        title: 'ئىشلىتىش كېلىشىمنامىسى',
        class: 'kt-portlet--fit kt-portlet--head-noborder',
        headClass: 'kt-portlet__space-x',
        headNoBorder: true,
        bodyFluid: true
        },false))],1),_c('div',{staticClass:"col-xl-4 col-lg-6 order-lg-3 order-xl-1"},[_c('KTPortlet',{attrs:{"title":'يېڭى ئەزالار'},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-clean btn-sm btn-icon btn-icon-md",attrs:{"title":"يېڭىلاش"},on:{"click":_vm.refreshNewlyUser}},[_c('i',{staticClass:"flaticon2-refresh-arrow"})])]},proxy:true},{key:"body",fn:function(){return [_c('UserList',{attrs:{"datasrc":_vm.users,"busy":_vm.userBusy}})]},proxy:true}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }